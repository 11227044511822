<template>
  <el-main>
    <el-row type="flex">
      <el-col id="rh-user">
        <h2>Changer le mot de passe</h2>
        <el-form
          ref="formUpdatepw"
          :model="form"
          :rules="rules"
          :validate-on-rule-change="false"
          :hide-required-asterisk="true"
        >
          <el-form-item label="Mot de passe actuel" prop="current_password">
            <el-input
              ref="currentpw"
              v-model="form.current_password"
              show-password
            />
          </el-form-item>
          <el-form-item label="Nouveau mot de passe" prop="password">
            <el-input v-model="form.password" show-password />
          </el-form-item>
          <el-form-item
            label="Confirmez le mot de passe"
            prop="password_confirmation"
          >
            <el-input v-model="form.password_confirmation" show-password />
          </el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            @click.prevent="updatePassword"
            >Envoyer</el-button
          >
        </el-form>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { getUserError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
export default {
  name: "UserupdatepwView",
  data() {
    const validePw = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Ce champ est obligatoire"));
      } else if (this.form.current_password) {
        if (value.toString() == this.form.current_password) {
          callback(
            new Error("Le nouveau mot de passe doit être différent de l'actuel")
          );
        } else if (value.toString().length < 8) {
          callback(
            new Error("Le mot de passe doit avoir au moins 8 caractères")
          );
        } else {
          callback();
        }
      } else if (value.toString().length < 8) {
        callback(new Error("Le mot de passe doit avoir au moins 8 caractères"));
      } else {
        callback();
      }
    };
    const valideConfirmpw = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Ce champ est obligatoire"));
      } else if (value.toString() != this.form.password.toString()) {
        callback(
          new Error("La confirmation ne correspond pas au mot de passe")
        );
      } else {
        callback();
      }
    };
    return {
      form: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
      error: null,
      message: null,
      rules: {
        current_password: [
          {
            required: true,
            //whitespace: false,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            whitespace: false,
            validator: validePw,
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            whitespace: false,
            validator: valideConfirmpw,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.$refs.currentpw.focus();
  },
  methods: {
    updatePassword() {
      this.$refs.formUpdatepw.validate(async (valid) => {
        if (valid) {
          this.error = null;
          this.message = null;
          const payload = this.form;
          AuthService.updatePassword(payload)
            .then(() => (this.message = "Mot de passe mis à jour."))
            .catch((error) => (this.error = getUserError(error)));
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.$message.error(this.error);
      }
    },
    message() {
      if (this.message) {
        this.$message({
          message: this.message,
          type: "success",
        });
      }
    },
  },
};
</script>
